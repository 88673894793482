import React from 'react';
import './styles.scss';
import LocalizedLink from '../LocalizedLink/LocalizedLink';

interface SalesButtonProps {
  big?: boolean
  children?: React.ReactNode
  className?: string
  campaign?: string
  theme?: string
  positionTag?: string
}

const SalesButton: React.FC<SalesButtonProps> = ({children, big, className, theme, campaign, positionTag}) => {
  const selectedTheme = theme === 'light' ? 'basic' : 'primary'
  return (
    <LocalizedLink to="/form-lead">
      <button
        className={`
        btn--${selectedTheme}
        ${big ? 'btn--lg': ''}
        ${className} contact-dropdown-button-sales`
      }
      >
        {children}
      </button>
    </LocalizedLink>
  )
}

export default SalesButton
