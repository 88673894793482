import * as React from 'react';
import IndexLayout from '../layouts';

import AboutUsCountries from '../components/AboutUs/AboutUsCountries/AboutUsCountries';
import AboutUsCTA from '../components/AboutUs/AboutUsCTA/AboutUsCTA';
import AboutUsHero from '../components/AboutUs/AboutUsHero/AboutUsHero';
import AboutUsHiring from '../components/AboutUs/AboutUsHiring/AboutUsHiring';
import AboutUsStats from '../components/AboutUs/AboutUsStats/AboutUsStats';
import AboutUsTeam from '../components/AboutUs/AboutUsTeam/AboutUsTeam';
import Footer from '../components/Footer/Footer';
import SEO from '../components/SEO/SEO';
import { LocaleTypes } from '../data/locales';

interface AboutUsPageProps {
  pathContext: {
    localeCode: LocaleTypes;
  };
  location: Location;
}

const AboutUsPage: React.FC<AboutUsPageProps> = ({ pathContext, location }) => {

  const content = () => (
    <>
      <SEO titleId="company.title" descriptionId="company.description"/>
      <AboutUsHero />

      <AboutUsTeam/>
      <AboutUsHiring/>

      <AboutUsStats/>

      <AboutUsCountries/>

      <AboutUsCTA/>
      <Footer theme="primary" />
    </>
  );

  return <IndexLayout render={ content } locale={pathContext.localeCode} navbarTheme="light" location={location} />
}

export default AboutUsPage;
