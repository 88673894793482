import React from 'react';
import { FormattedMessage } from 'react-intl';
import { defaultFormatValues } from '../../../data/defaultFormat';

import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

const AboutUsTeam: React.FC = ({ children }) => {
  const { teamImg } = useStaticQuery(
    graphql`
      query {
        teamImg: file(relativePath: {eq: "aboutUs/kushki-team.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `
  );

  return (
    <section className="section-team bg-light">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h1 className="text-primary mb-5">
              <FormattedMessage id="company.team.section_1.title"
                values={defaultFormatValues}
              />
            </h1>
            <p className="lead">
              <FormattedMessage id="company.team.section_1.subtitle"
                values={defaultFormatValues}
              />
            </p>
          </div>
        </div>

      </div>

      <BackgroundImage
        Tag="div"
        fluid={teamImg.childImageSharp.fluid}
        style={{
          backgroundAttachment: 'fixed',
          backgroundPosition: '50% 50%',
          backgroundSize: 'cover',
          height: '500px',
          marginTop: '6rem',
          width: '100%',
        }}
      />

      <section className="section-xl">
        <div className="container py-5">
          <h1 className="mb-5 text-primary">
            <FormattedMessage id="company.team.section_2.title"
              values={defaultFormatValues}
            />
          </h1>
          <div className="row">
            <div className="col-md-6">
              <p style={{whiteSpace: 'pre-wrap'}}>
                <FormattedMessage id="company.team.section_2.body_1"
                  values={defaultFormatValues}
                />
              </p>
            </div>
            <div className="col-md-6">
              <p style={{whiteSpace: 'pre-wrap'}}>
                <FormattedMessage id="company.team.section_2.body_2"
                  values={defaultFormatValues}
                />
              </p>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default AboutUsTeam;
