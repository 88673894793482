import React from 'react';
import { FormattedMessage } from 'react-intl';

const AboutUsStats: React.FC = ({ children }) => (
  <section className="section-xl">
    <div className="container">
      <div className="row text-center mb-7">
        <div className="col-md-10 offset-md-1">
          <h2>
            <FormattedMessage id="company.stats.title"/>
          </h2>
        </div>
      </div>

      <div className="row text-center">
        <div className="col-md-4">
          <h1 className="display-2 text-primary">75.000</h1>
          <h5>
            <FormattedMessage id="company.stats.stat_1"/>
          </h5>
        </div>
        <div className="col-md-4">
          <h1 className="display-2 text-primary">+200</h1>
          <h5>
            <FormattedMessage id="company.stats.stat_2"/>
          </h5>
        </div>
        <div className="col-md-4">
          <h1 className="display-2 text-primary">99.99%</h1>
          <h5>
            <FormattedMessage id="company.stats.stat_3"/>
          </h5>
        </div>
      </div>
    </div>
  </section>
);

export default AboutUsStats;
