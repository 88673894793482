import React from 'react';
import { FormattedMessage } from 'react-intl';
import { defaultFormatValues } from '../../../data/defaultFormat';
import countriesImg from './assets/images/paises.svg';

const AboutUsCountries: React.FC = ({children}) => (
  <section className="section-xl bg-light-gray-3">
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <h1 className="mb-6">
            <FormattedMessage id="company.countries.title"/>
          </h1>
          <p style={{whiteSpace: 'pre-wrap'}}>
            <FormattedMessage id="company.countries.subtitle"
              values={defaultFormatValues}
            />
          </p>

          <a className="btn--basic mt-6" href="mailto:contacto@kushkipagos.com">
            <FormattedMessage id="company.countries.button"/>
          </a>
        </div>
        <div className="col-md-6">
          <img src={countriesImg} className="img-fluid mt-7 mt-md-0" alt="Countries images"/>
        </div>
      </div>
    </div>
  </section>
);

export default AboutUsCountries;
