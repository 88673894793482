import React from 'react';
import { FormattedMessage } from 'react-intl';
import { defaultFormatValues } from '../../../data/defaultFormat';
import './styles.scss';

const AboutUsHero: React.FC = ({ children }) => (
  <section className="hero hero-about-us section-xl text-light">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-xl-8 offset-xl-2">
          <h1 className="display-4 mt-layout-3 mt-sm-layout-5 mt-md-0">
            <FormattedMessage id="company.hero.title"
              values={defaultFormatValues}
            />
          </h1>
          <p className="lead mt-5" style={{whiteSpace: 'pre-wrap'}}>
            <FormattedMessage id="company.hero.subtitle"
              values={defaultFormatValues}
            />
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default AboutUsHero;
