import React from 'react';
import { FormattedMessage } from 'react-intl';
import { defaultFormatValues } from '../../../data/defaultFormat';

import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import { OutboundLink } from 'gatsby-plugin-google-gtag';

import './styles.scss';
import LocalizedLink from '../../LocalizedLink/LocalizedLink';

const AboutUsHiring: React.FC = ({ children }) => {
  const { teamImg } = useStaticQuery(
    graphql`
      query {
        teamImg: file(relativePath: {eq: "aboutUs/team2.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `
  );

  return (
    <>
      <section className="section-image-offset section-xl bg-light-gray-1-5">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <h1 className="text-primary">
                <FormattedMessage id="company.hiring.section_1.title"
                  values={{
                    ...defaultFormatValues,
                    separator: <br className="d-none d-md-inline" />
                  }}
                />
              </h1>
            </div>
            <div className="col-md-7">
              <h4 className="mb-5 mt-5 mt-md-0">
                <FormattedMessage id="company.hiring.section_1.lead"/>
              </h4>
              <p style={{whiteSpace: 'pre-wrap'}}>
                <FormattedMessage id="company.hiring.section_1.body"
                  values={defaultFormatValues}
                />
              </p>
            </div>
          </div>
        </div>
        <Img fluid={teamImg.childImageSharp.fluid} className="image-offset" alt="Team photo" style={{position: 'absolute'}} />
      </section>

      <section className="section-xl">
        <div className="container">
          <div className="text-center mb-7">
            <h1>
              <FormattedMessage id="company.hiring.section_2.title"/>
            </h1>
            <p className="lead mt-5">
              <FormattedMessage id="company.hiring.section_2.lead"/>
            </p>
          </div>

          <div className="row">
            <div className="col-md-6">
              <p style={{whiteSpace: 'pre-wrap'}}>
                <FormattedMessage id="company.hiring.section_2.body_1"
                  values={defaultFormatValues}
                />
              </p>
            </div>
            <div className="col-md-6">
              <p style={{whiteSpace: 'pre-wrap'}}>
                <FormattedMessage id="company.hiring.section_2.body_2"
                  values={defaultFormatValues}
                />
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="section-xl bg-dark section-hiring-cta">
        <div className="container text-center">
          <h1 className="display-4 text-success mb-5">
            <FormattedMessage id="company.hiring.section_3.title"/>
          </h1>
          <p className="lead mb-7 text-white">
            <FormattedMessage id="company.hiring.section_3.subtitle"
              values={defaultFormatValues}
            />
          </p>
          <LocalizedLink to='/work-with-us' className="btn--outline_white">
            <FormattedMessage id="company.hiring.section_3.button"/>
          </LocalizedLink>
        </div>
        <div className="left-decorators"/>
        <div className="right-decorators"/>
      </section>
    </>
  );
};

export default AboutUsHiring;
