import React from 'react';
import { FormattedMessage } from 'react-intl';
import ctaImg from './assets/images/cta.svg';
import './styles.scss';

import SalesButton from '../../SalesButton/SalesButton';

const AboutUsCTA: React.FC = ({children}) => (
  <>
    <section className="section-xl">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <img className="img-fluid" src={ctaImg} alt="CTA image" />
          </div>
          <div className="col-md-6">
            <h2 className="mt-7 mt-md-0">
              <FormattedMessage id="company.cta.title"/>
            </h2>
            <p className="lead">
              <FormattedMessage id="company.cta.subtitle"/>
            </p>
            <div className="button-responsive-row mt-7">
            <SalesButton big={true} positionTag="bottom">
              <FormattedMessage id="buttons.sales"/>
            </SalesButton>
          </div>
          </div>
        </div>
      </div>
    </section>

    <div className="pre-footer-bg"/>
  </>
);

export default AboutUsCTA;
